import { memo } from 'react'

import { Text } from '@cash-me/react-components'
import NextImage from "next/image"

import { Grid, Col } from 'components/grid'

import * as S from './styles'

const Statistics = memo(() => (
  <S.Statistics>
    <Grid col={{ phone: 1, tablet: 1 }}>
      <Col col={{ desktop: 11 }} start={{ desktop: 2 }}>
        <div>
          <S.ImageWrapper>
            <NextImage
              src={`${process.env.NEXT_PUBLIC_IMAGES_PREFIX}/home/statistics.png?updated=03072022`}
              alt="R$1,3 Bilhões de crédito disponibilizado para os nossos clientes."
              width="1366"
              height="587"
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto"
              }} />
          </S.ImageWrapper>
          <S.LinearGradient />
          <S.TextWrapper>
            <Text className="statistics-number" size="heading2" weight="medium">
              R$ 2 Bilhões
            </Text>
            <Text className="statistics-text" size="body2">
              de crédito disponibilizado para os nossos clientes.
            </Text>
          </S.TextWrapper>
        </div>
      </Col>
    </Grid>
  </S.Statistics>
))

Statistics.displayName = 'Statistics'

export default Statistics
